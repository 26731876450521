import React from 'react';
import {Stack} from 'ui-kit';
import {Helmet} from 'react-helmet';

import {CardContainer} from '../../components/CardContainer';

export function NotFound() {
  return (
    <>
      <Helmet>
        <title>Not found | Pay dental invoices online</title>
      </Helmet>
      <CardContainer>
        <Stack vertical>
          <h1>Sorry!</h1>
          <p>
            We couldn't find that invoice. If you think this is a mistake,
            please contact your office for more information.
          </p>
        </Stack>
      </CardContainer>
    </>
  );
}
