import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const INVOICE_QUERY = gql`
  query Invoice {
    invoice {
      id
      totalPriceCents
      amountOutstandingCents
    }
    office {
      name
      phone
      email
    }
  }
`;

interface Data {
  invoice: {
    id: string;
    totalPriceCents: number;
    amountOutstandingCents: number;
  };
  office: {
    name: string;
    phone: string;
    email: string;
  };
}

export class InvoiceQuery extends Query<Data> {}
