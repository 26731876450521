import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const SUCCESS_QUERY = gql`
  query Success {
    office {
      name
      phone
      email
    }
    invoice {
      id
      amountOutstandingCents
    }
  }
`;

interface Data {
  office: {
    name: string;
    phone: string;
    email: string;
  };
  invoice: {
    id: string;
    amountOutstandingCents: number;
  };
}

export class SuccessQuery extends Query<Data> {}
