import React from 'react';
import {Subheading, Stack, Spinner} from 'ui-kit';
import {Elements} from 'react-stripe-elements';
import {Helmet} from 'react-helmet';

import {CardContainer, ErrorBanner} from '../../components';
import {CheckoutForm} from './components/CheckoutForm';

import {InvoiceQuery, INVOICE_QUERY} from './graphql/InvoiceQuery';
import {Redirect} from 'react-router';

enum PaymentStatus {
  Success = 'SUCCESS',
  Error = 'ERROR'
}

interface State {
  paymentStatus: PaymentStatus | null;
}

export default class Payment extends React.Component<{}, State> {
  state: State = {
    paymentStatus: null
  };

  render() {
    const {paymentStatus} = this.state;

    if (paymentStatus === PaymentStatus.Success) {
      return <Redirect to="success" />;
    }

    const numberFormatter = new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    const errorMarkup =
      paymentStatus === PaymentStatus.Error ? (
        <ErrorBanner>
          <small>There was a problem with your payment.</small>
        </ErrorBanner>
      ) : null;

    return (
      <>
        <Helmet>
          <title>Enter payment information | Pay dental invoices online</title>
        </Helmet>
        <InvoiceQuery query={INVOICE_QUERY}>
          {({loading, error, data}) => {
            if (loading) {
              return (
                <CardContainer>
                  <Stack alignment="center" distribution="center">
                    <Spinner size="medium" />
                  </Stack>
                </CardContainer>
              );
            }

            if (error || data == null) {
              console.log(error);
              return (
                <CardContainer>
                  <Stack alignment="center" distribution="center">
                    <p>Uh oh, an error occurred :(</p>
                  </Stack>
                </CardContainer>
              );
            }

            const {invoice, office} = data;

            if (invoice.amountOutstandingCents === 0) {
              return <Redirect to={`/${invoice.id}/success`} />;
            }

            return (
              <CardContainer
                officeName={office.name}
                officeEmail={office.email}
                officePhone={office.phone}
              >
                <Stack vertical>
                  <Subheading>Enter your payment information</Subheading>

                  {errorMarkup}

                  <strong>
                    You will be charged{' '}
                    {numberFormatter.format(invoice.totalPriceCents / 100)}.
                  </strong>

                  <Elements>
                    <CheckoutForm
                      onPaymentComplete={() =>
                        this.setState({paymentStatus: PaymentStatus.Success})
                      }
                      onPaymentError={() =>
                        this.setState({paymentStatus: PaymentStatus.Error})
                      }
                      total={invoice.totalPriceCents}
                    />
                  </Elements>
                </Stack>
              </CardContainer>
            );
          }}
        </InvoiceQuery>
      </>
    );
  }
}
