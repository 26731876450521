import styled from 'react-emotion';
import {colors} from '../../tokens';

export const Row = styled('tr')({
  borderBottom: `1px solid ${colors.gray}`,

  '&:nth-of-type(even)': {
    background: colors.gray
  }
});
