import React from 'react';
import styled from 'react-emotion';
import {Redirect, RouteComponentProps} from 'react-router-dom';
import {graphql, compose, ChildMutateProps} from 'react-apollo';
import gql from 'graphql-tag';
import {Helmet} from 'react-helmet';

import {Button, Subheading, Stack, Label, Input, spacing} from 'ui-kit';
import {autobind} from 'utilities';

import {CardContainer} from '../../components/CardContainer';

enum AuthStatus {
  Loading = 'LOADING',
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  AuthFailed = 'AUTH_FAILED',
  Success = 'SUCCESS'
}

interface State {
  email: string;
  dateOfBirth: string;
  status: AuthStatus | null;
}

type Props = ChildMutateProps<
  {email: string; dateOfBirth: string; invoiceId: string},
  {
    authenticateInvoice: {
      token?: string;
      errors?: {code: string; message: string}[];
    } | null;
  }
> &
  RouteComponentProps<{invoiceId: string}>;

const ErrorBanner = styled('div')({
  background: '#fff2f2',
  borderRadius: 4,
  border: '1px solid lightcoral',
  padding: spacing.base
});

class Auth extends React.Component<Props, State> {
  state: State = {
    email: '',
    dateOfBirth: '',
    status: null
  };

  render() {
    const {email, dateOfBirth, status} = this.state;
    const {
      match: {
        params: {invoiceId}
      }
    } = this.props;

    if (status === AuthStatus.Success) {
      return <Redirect to={`/${invoiceId}/review`} />;
    }

    if (status === AuthStatus.NotFound) {
      return <Redirect to="/not-found" />;
    }

    const errorBanner =
      status === AuthStatus.AuthFailed ? (
        <ErrorBanner>
          <small>
            Sorry, the email and date of birth provided do not match the
            invoice.
          </small>
        </ErrorBanner>
      ) : status === AuthStatus.Error ? (
        <ErrorBanner>
          <small>
            Sorry, we are having trouble contacting our servers. Please try
            again later.
          </small>
        </ErrorBanner>
      ) : null;

    const dateIsInvalid =
      this.state.status === AuthStatus.Error &&
      dateOfBirth.match(/[1-9]{4}\-[0-9]{2}\-[0-9]{2}/) == null;

    return (
      <>
        <Helmet>
          <title>Log in | Pay dental invoices online</title>
        </Helmet>
        <CardContainer>
          <form method="post" onSubmit={this.handleSubmit}>
            <Stack vertical>
              <Stack.Item>
                <Subheading>Hi!</Subheading>
                <p>Let's make sure you're in the right place.</p>
              </Stack.Item>

              {errorBanner}

              <Stack.Item>
                <Label>Email</Label>
                <Input value={email} onChange={this.handleEmailChange} />
              </Stack.Item>

              <Stack.Item>
                <Label>Date of birth (YYYY-MM-DD)</Label>
                <Input
                  status={dateIsInvalid ? 'error' : undefined}
                  value={dateOfBirth}
                  onChange={this.handleDateOfBirthChange}
                />
                {dateIsInvalid ? (
                  <small>Please enter a date in YYYY-MM-DD format.</small>
                ) : null}
              </Stack.Item>

              <Button
                color="blue"
                fullWidth
                loading={status === AuthStatus.Loading}
              >
                Continue
              </Button>
            </Stack>
          </form>
        </CardContainer>
      </>
    );
  }

  @autobind
  private handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState({email: value});
  }

  @autobind
  private handleDateOfBirthChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState({dateOfBirth: value});
  }

  @autobind
  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.setState({status: AuthStatus.Loading});

    const {email, dateOfBirth} = this.state;
    const {
      match: {
        params: {invoiceId}
      },
      mutate
    } = this.props;

    mutate({variables: {email, dateOfBirth, invoiceId}}).then(
      ({data: {authenticateInvoice}}) => {
        if (authenticateInvoice == null) {
          this.setState({status: AuthStatus.Error});
          return;
        }

        if (authenticateInvoice.errors != null) {
          const [error] = authenticateInvoice.errors;

          if (error.code == 'UNAUTHORIZED') {
            this.setState({status: AuthStatus.AuthFailed});
          } else if (error.code == 'NOT_FOUND') {
            this.setState({status: AuthStatus.NotFound});
          }

          return;
        }

        const {token} = authenticateInvoice;

        if (token == null) {
          return this.setState({status: AuthStatus.Error});
        }

        sessionStorage.setItem('token', token);
        this.setState({status: AuthStatus.Success});
      }
    );
  }
}

export default compose(
  graphql(
    gql`
      mutation authenticateInvoice(
        $invoiceId: ID!
        $email: String!
        $dateOfBirth: String!
      ) {
        authenticateInvoice(
          invoiceId: $invoiceId
          email: $email
          dateOfBirth: $dateOfBirth
        ) {
          token
          errors {
            code
            message
          }
        }
      }
    `,
    {
      options: {
        context: {
          uri:
            process.env.ENV === 'production'
              ? 'https://molar-221002.appspot.com/auth'
              : 'http://localhost:3000/auth'
        }
      }
    }
  )
)(Auth);
