import React from 'react';
import styled from 'react-emotion';
import ReactModal from 'react-modal';
import {autobind} from 'utilities';
import {Button, Stack, Subheading, boxShadow, spacing} from 'ui-kit';

interface Props {
  officeName?: string;
  officePhone?: string;
  officeEmail?: string;
}

interface State {
  open: boolean;
}

const HelpTextContainer = styled('div')({
  color: '#777',
  padding: `${spacing.base}px 0`,
  textAlign: 'center'
});

ReactModal.setAppElement('#root');

export class ContactInfo extends React.Component<Props, State> {
  state: State = {
    open: false
  };

  render() {
    const {officeName, officeEmail, officePhone} = this.props;
    const officeInfoAvailable =
      officeName != null && (officeEmail != null || officePhone != null);

    const contactIntroText = officeInfoAvailable ? (
      <p>
        For questions regarding your invoice or office visit, please contact
        your dental practice:
      </p>
    ) : null;

    const officeContactMarkup = officeInfoAvailable ? (
      <p>
        <strong>{officeName}</strong> <br />
        {officePhone} <br />
        <a href={`mailto:${officeEmail}`}>{officeEmail}</a>
      </p>
    ) : null;

    return (
      <>
        <HelpTextContainer>
          <small>
            Need help? Feel free to{' '}
            <a href="#" onClick={this.openModal}>
              contact us
            </a>.
          </small>
        </HelpTextContainer>
        <ReactModal
          contentLabel="Contact information"
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          onRequestClose={this.closeModal}
          isOpen={this.state.open}
          style={{
            overlay: {
              background: 'rgba(0, 0, 0, 0.2)'
            },
            content: {
              position: 'relative',
              top: 100,
              left: 0,
              right: 0,
              bottom: 0,
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: 6,
              outline: 'none',
              margin: 'auto',
              maxWidth: 500,
              boxShadow: boxShadow.base
            }
          }}
        >
          <Stack vertical>
            <Subheading>Contact us</Subheading>

            {contactIntroText}

            {officeContactMarkup}

            <p>
              For questions about this software or to report a problem, please
              contact us at <a href="mailto:dev@molar.app">dev@molar.app</a>.
            </p>

            <Button fullWidth onClick={this.closeModal}>
              Close
            </Button>
          </Stack>
        </ReactModal>
      </>
    );
  }

  @autobind
  private openModal(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    this.setState({open: true});
  }

  @autobind
  private closeModal() {
    this.setState({open: false});
  }
}
