import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {ApolloProvider} from 'react-apollo';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache, NormalizedCacheObject} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {onError} from 'apollo-link-error';
import {StripeProvider} from 'react-stripe-elements';
import {autobind, ProtectedRoute} from 'utilities';
import {Helmet} from 'react-helmet';

import {Auth} from './sections/Auth';
import {NotFound} from './sections/NotFound';
import {Review} from './sections/Review';
import {Payment} from './sections/Payment';
import {Success} from './sections/Success';

interface State {
  authenticated: boolean;
}

const STRIPE_API_KEY =
  process.env.ENV === 'production'
    ? 'pk_live_wcrsaOhPNWzrM8LTZJ0eumcW'
    : 'pk_test_SsaYtVBNjO05y3DGp66kkzrE';

export class App extends React.Component<{}, State> {
  state: State = {
    authenticated: true
  };

  private client: ApolloClient<NormalizedCacheObject>;

  constructor(props: {}) {
    super(props);

    const uri =
      process.env.ENV === 'production'
        ? 'https://molar-221002.appspot.com/invoice_graphql'
        : 'http://localhost:3000/invoice_graphql';

    const httpLink = createHttpLink({uri});
    const authLink = setContext((_, {headers}) => {
      const token = sessionStorage.getItem('token');

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    });
    const errorLink = onError(({networkError}) => {
      if (networkError == null) {
        return;
      }

      if ((networkError as any).statusCode === 401) {
        sessionStorage.removeItem('token');
        this.updateAuth(false);
      }
    });

    this.client = new ApolloClient({
      link: authLink.concat(errorLink).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only'
        }
      }
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Pay dental invoices online</title>
        </Helmet>
        <Router>
          <ApolloProvider client={this.client}>
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Switch>
                <Route path="/not-found" component={NotFound} />
                <Route exact path="/:invoiceId" component={Auth} />
                <ProtectedRoute
                  exact
                  path="/:invoiceId/review"
                  component={Review}
                />
                <ProtectedRoute
                  exact
                  path="/:invoiceId/payment"
                  component={Payment}
                />
                <ProtectedRoute
                  exact
                  path="/:invoiceId/success"
                  component={Success}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </StripeProvider>
          </ApolloProvider>
        </Router>
      </>
    );
  }

  @autobind
  private updateAuth(authenticated: boolean) {
    this.setState({authenticated});
  }
}
