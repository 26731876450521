import React from 'react';
import styled from 'react-emotion';
import {Card} from 'ui-kit';

import {ContactInfo} from './components/ContactInfo';

interface Props {
  children: React.ReactNode;
  officeName?: string;
  officePhone?: string;
  officeEmail?: string;
}

const Container = styled('div')({
  margin: 'auto',
  maxWidth: 600,
  padding: '40px 0',
  width: '100%',

  '@media (min-width: 768px)': {
    paddingTop: 80
  }
});

export function CardContainer({
  children,
  officeName,
  officePhone,
  officeEmail
}: Props) {
  return (
    <Container>
      <Card>{children}</Card>
      <ContactInfo
        officeEmail={officeEmail}
        officeName={officeName}
        officePhone={officePhone}
      />
    </Container>
  );
}
