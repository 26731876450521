import React from 'react';
import {
  injectStripe,
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  PostalCodeElement,
  PaymentRequestButtonElement
} from 'react-stripe-elements';
import {autobind} from 'utilities';
import {Button, Label, Stack, Input} from 'ui-kit';
import styled from 'react-emotion';
import {compose, graphql, MutateProps} from 'react-apollo';
import gql from 'graphql-tag';

interface Props {
  onPaymentComplete(): void;
  onPaymentError(): void;
  total: number;
}

type ComposedProps = Props & {stripe: stripe.Stripe} & MutateProps<
    {errors: string[]},
    {token: string; amount: number}
  >;

interface State {
  name: string;
  loading: boolean;
  canMakePayment: boolean;
}

const MockInput = styled('div')({
  border: '1px solid #ccc',
  borderRadius: 6,
  display: 'block',
  padding: '8px 12px',
  transition: 'border 0.2s',
  width: '100%',

  '&:focus': {
    border: '1px solid #444',
    outline: 0
  }
});

class CheckoutForm extends React.Component<ComposedProps, State> {
  state = {
    name: '',
    loading: false,
    canMakePayment: false
  };

  private paymentRequest = this.props.stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Total',
      amount: this.props.total
    }
  });

  componentDidMount() {
    this.paymentRequest.on('token', ({complete, token, ...data}) => {
      this.postPayment(token.id)
        .then(() => {
          complete('success');
          this.props.onPaymentComplete();
        })
        .catch(err => this.props.onPaymentError());

      console.log('received stripe token: ', token);
      console.log('received customer information: ', data);
      complete('success');
    });

    this.paymentRequest.canMakePayment().then(result => {
      this.setState({canMakePayment: !!result});
    });
  }

  render() {
    const paymentRequestButton = this.state.canMakePayment ? (
      <Stack vertical>
        <PaymentRequestButtonElement paymentRequest={this.paymentRequest} />
        <p>Or pay by credit card:</p>
      </Stack>
    ) : null;

    return (
      <form onSubmit={this.handleSubmit} method="POST">
        <Stack vertical>
          {paymentRequestButton}

          <div>
            <Label>Cardholder name</Label>
            <Input onChange={this.handleNameChange} value={this.state.name} />
          </div>

          <Stack.Item>
            <Label>Card number</Label>
            <MockInput>
              <CardNumberElement style={{base: {fontSize: '16px'}}} />
            </MockInput>
          </Stack.Item>

          <Stack>
            <Stack.Item fill>
              <Label>
                Expiration <small>(MM/YY)</small>
              </Label>
              <MockInput>
                <CardExpiryElement style={{base: {fontSize: '16px'}}} />
              </MockInput>
            </Stack.Item>
            <Stack.Item fill>
              <Label>CVC</Label>
              <MockInput>
                <CardCVCElement style={{base: {fontSize: '16px'}}} />
              </MockInput>
            </Stack.Item>
            <Stack.Item fill>
              <Label>ZIP Code</Label>
              <MockInput>
                <PostalCodeElement style={{base: {fontSize: '16px'}}} />
              </MockInput>
            </Stack.Item>
          </Stack>

          <Button
            color="blue"
            fullWidth
            type="submit"
            loading={this.state.loading}
          >
            Submit payment
          </Button>
        </Stack>
      </form>
    );
  }

  @autobind
  private handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    this.setState({name: value});
  }

  @autobind
  private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    this.setState({loading: true});

    const {name} = this.state;

    this.props.stripe
      .createToken({name})
      .then(({error, token}: stripe.TokenResponse) => {
        if (error != null) {
          this.setState({loading: false});
          this.props.onPaymentError();
          throw new Error(error.message);
        }

        if (token == null) {
          this.setState({loading: false});
          this.props.onPaymentError();
          throw new Error('Token is null');
        }

        return this.postPayment(token.id);
      })
      .then(({data: {createInvoicePayment: data}}) => {
        this.setState({loading: false});

        if (data.errors != null) {
          this.props.onPaymentError();
          return;
        }

        this.props.onPaymentComplete();
      })
      .catch((error: string) => {
        console.error(error);
        this.props.onPaymentError();
        this.setState({loading: false});
      });
  }

  @autobind
  private postPayment(token: string) {
    const {total} = this.props;

    return this.props.mutate({variables: {token, amount: total}});
  }
}

export default compose(
  injectStripe,
  graphql(gql`
    mutation MakePaymentMutation($token: String!, $amount: Int!) {
      createInvoicePayment(paymentToken: $token, amount: $amount) {
        errors
      }
    }
  `)
)(CheckoutForm);
