import {Query} from 'react-apollo';
import gql from 'graphql-tag';

export const INVOICE_QUERY = gql`
  query Invoice {
    invoice {
      id
      title
      number
      dueAt
      dateOfService
      totalPriceCents
      amountOutstandingCents
      patient {
        firstName
        lastName
        address
        postalCode
        city
        state
        postalCode
      }
    }
    office {
      name
      phone
      email
    }
  }
`;

interface Data {
  invoice: {
    id: string;
    title: string;
    number: string;
    dueAt: string;
    dateOfService: string;
    totalPriceCents: number;
    amountOutstandingCents: number;
    patient: {
      firstName: string;
      lastName: string;
      address: string;
      city: string;
      state: string;
      postalCode: string;
    };
  };
  office: {
    name: string;
    phone: string;
    email: string;
  };
}

export class InvoiceQuery extends Query<Data> {}
