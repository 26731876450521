import styled from 'react-emotion';
import {colors} from '../../tokens';

interface Props {
  status?: 'error';
}

export const Input = styled('input')<Props>(({status}) => {
  const borderColor = status === 'error' ? colors.red : '#ccc';

  return {
    border: `1px solid ${borderColor}`,
    borderRadius: 6,
    display: 'block',
    padding: '8px 12px',
    transition: 'border 0.2s',
    width: '100%',

    '&:focus': {
      border: '1px solid #444',
      outline: 0
    }
  };
});
