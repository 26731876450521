import styled from 'react-emotion';
import {spacing, boxShadow} from '../../tokens';

export const Card = styled('div')({
  background: '#fff',
  border: '1px solid #eee',
  borderRadius: 5,
  boxShadow: boxShadow.base,
  padding: spacing.loose,
  width: '100%'
});
