import React from 'react';
import {Redirect} from 'react-router-dom';
import {Button, Spinner, Stack, Subheading} from 'ui-kit';
import {Helmet} from 'react-helmet';

import {CardContainer} from '../../components/CardContainer';

import {INVOICE_QUERY, InvoiceQuery} from './graphql/InvoiceQuery';

export function Review() {
  return (
    <>
      <Helmet>
        <title>Review invoice | Pay dental invoices online</title>
      </Helmet>
      <InvoiceQuery query={INVOICE_QUERY}>
        {({data, loading, error}) => {
          if (loading) {
            return (
              <CardContainer>
                <Stack alignment="center" distribution="center">
                  <Spinner size="medium" />
                </Stack>
              </CardContainer>
            );
          }

          if (error || data == null) {
            console.log(error);
            return (
              <CardContainer>
                <Stack alignment="center" distribution="center">
                  <p>Uh oh, an error occurred :(</p>
                </Stack>
              </CardContainer>
            );
          }

          const {invoice, office} = data;
          const {patient} = invoice;

          if (invoice.amountOutstandingCents === 0) {
            return <Redirect to={`/${invoice.id}/success`} />;
          }

          return (
            <CardContainer
              officeName={office.name}
              officeEmail={office.email}
              officePhone={office.phone}
            >
              <Stack vertical>
                <Subheading>Review your invoice</Subheading>

                <Stack vertical spacing="none">
                  <p>
                    <strong>{invoice.number}</strong> -{' '}
                    <strong>{invoice.title}</strong>
                  </p>

                  <small>Service performed on {invoice.dateOfService}</small>
                </Stack>

                <Stack vertical spacing="none">
                  <strong>Bill to:</strong>
                  <p>
                    {patient.firstName} {patient.lastName}
                  </p>
                  <p>{patient.address}</p>
                  <p>
                    {patient.city}, {patient.state} {patient.postalCode}
                  </p>
                </Stack>

                <Stack alignment="baseline" spacing="tight">
                  <h2>
                    {new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: 'usd'
                    }).format(invoice.totalPriceCents / 100)}
                  </h2>
                  <small>due on {invoice.dueAt}</small>
                </Stack>

                <Button color="blue" fullWidth href="payment">
                  Continue to payment
                </Button>
              </Stack>
            </CardContainer>
          );
        }}
      </InvoiceQuery>
    </>
  );
}
