import * as React from 'react';
import {Stack, Spinner} from 'ui-kit';
import {Helmet} from 'react-helmet';

import {SuccessQuery, SUCCESS_QUERY} from './graphql/SuccessQuery';

import {CardContainer} from '../../components/CardContainer';
import {Redirect} from 'react-router';

export function Success() {
  return (
    <>
      <Helmet>
        <title>Payment successful | Pay dental invoices online</title>
      </Helmet>
      <SuccessQuery query={SUCCESS_QUERY} fetchPolicy="network-only">
        {({loading, error, data}) => {
          if (loading) {
            return (
              <CardContainer>
                <Stack alignment="center" distribution="center">
                  <Spinner size="medium" />
                </Stack>
              </CardContainer>
            );
          }

          if (error || data == null) {
            console.log(error);
            return (
              <CardContainer>
                <Stack alignment="center" distribution="center">
                  <p>Uh oh, an error occurred :(</p>
                </Stack>
              </CardContainer>
            );
          }

          const {office, invoice} = data;

          if (invoice.amountOutstandingCents !== 0) {
            return <Redirect to={`/${invoice.id}/review`} />;
          }

          return (
            <CardContainer
              officeEmail={office.email}
              officeName={office.name}
              officePhone={office.phone}
            >
              <Stack vertical>
                <h2>Thank you!</h2>
                <p>Your payment was completed successfully.</p>
                <small>
                  If you have questions about your invoice, please contact{' '}
                  {office.name}:
                  <ul>
                    <li>
                      Phone: <a href={`tel:${office.phone}`}>{office.phone}</a>
                    </li>
                    <li>
                      Email:{' '}
                      <a href={`mailto:${office.email}`}>{office.email}</a>
                    </li>
                  </ul>
                </small>
              </Stack>
            </CardContainer>
          );
        }}
      </SuccessQuery>
    </>
  );
}
